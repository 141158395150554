import { createContext, ReactNode, useContext } from 'react';
import * as Sentry from '@sentry/react';

export interface ClientUser {
  id: string;
  email?: string;
  name: string;
  title?: string;
  admin: boolean;
}

const context = createContext<ClientUser | null>(null);

export function UserProvider(props: { user: ClientUser; children: ReactNode }): JSX.Element {
  if (props.user.email) {
    Sentry.setUser({ id: props.user.id, email: props.user.email });
  }

  return <context.Provider value={props.user}>{props.children}</context.Provider>;
}

export function useUser(): ClientUser {
  const user = useContext(context);

  if (user === null) {
    throw new Error('useUser() must be used within a <UserProvider />');
  }

  return user;
}
